@use 'styles/color';

.container {
  position: relative;

  &.wide {
    width: 100%;
  }
}

.icon-container {
  position: absolute;
  top: 12px;
  left: 16px;
}

.input {
  width: 100%;
  height: 40px;
  padding: 9px 9px 9px 40px;
  border: 1px solid color.$gray20;
  border-radius: 4px;
  font-family: 'Noto Sans JP', sans-serif;
  color: color.$black;
  font-size: 15px;
  line-height: 22px;

  &::placeholder {
    font-family: 'Noto Sans JP', sans-serif;
    color: color.$gray30;
    font-size: 15px;
    line-height: 22px;
  }

  &.no-border {
    border: none;
  }

  &.no-focus:focus {
    outline: none;
  }
}
