@use 'styles/color';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: color.$white;
  padding: 24px 28px 28px 46px;
}
.overflow {
  overflow-x: scroll;
}
