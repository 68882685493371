@use 'styles/color';

.check-box {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid color.$gray20;

  svg {
    display: block;
    position: absolute;
    /* borderがなくなったときの位置 */
    top: 6px;
    left: 4px;
  }
}

.dummy-input {
  display: none;

  &:checked + .check-box {
    background-color: color.$soft-orange;
    border: none;
  }

  &:checked + .check-box::after {
    opacity: 1;
  }
}
