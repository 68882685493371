@use 'styles/color';

.bg-linen {
  background-color: color.$linen;
}

.container {
  display: flex;
  flex-direction: row;
  background-color: color.$white;
  width: 100%;
  padding: 0;
  height: auto;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
}

.parent-container {
  display: flex;
  flex-direction: column;
  width: 268px;
  padding: 24px 0px 0px 24px;
  box-shadow: 5px 0px 2px -2px #0000001a;
  height: auto;
}
.parent-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
}
.parent-image-container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  background-color: color.$gray10;
  align-items: center;
  justify-content: center;
}
.parent-name {
  width: 132px;
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray90;
}

.category-container {
  display: flex;
  flex-direction: column;
  width: 244px;
  padding: 24px 0px 0px 4px;
  box-shadow: 5px 0px 2px -2px #0000001a;
  height: auto;
}
.category-all {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}
.category-all p {
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray90;
  max-width: 186px;
}
.category-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
}
.category-container p {
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray90;
}

.child-section-container {
  display: flex;
  flex-direction: column;
  width: 512px;
  padding: 24px 0px 0px 4px;
}
.child-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 44px 8px 24px;
}
.child-all p {
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray90;
}
.child-item-wrapper {
  display: flex;
  flex-direction: initial;
  flex-wrap: wrap;
  overflow-y: scroll;
  width: 100%;
  padding: 0;
}
.child-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 243px;
  padding: 10px 24px;
}
.child-item-container p {
  font-size: 14px;
  line-height: 1.4;
  color: color.$gray90;
}

.box-item {
  flex-shrink: 0;
  flex-grow: 0;
}
