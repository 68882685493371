@use 'styles/color';

.bg-linen {
  background-color: color.$linen;
}

.p-orange {
  color: color.$soft-orange;
}
.p-gray {
  color: color.$gray90;
}

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 40px;
  padding: 16px 24px;
}
.input-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  align-items: center;
}
.input-title p {
  font-size: 12px;
  line-height: 1.4;
  font-weight: 700;
}
.input-item {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected {
  color: color.$gray90;
  font-weight: 700;
}
.unselected {
  color: color.$gray50;
  font-weight: 400;
}
