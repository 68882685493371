@use 'styles/color';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  background-color: color.$white;
  border-radius: 8px 8px 0 0;
}

.price-range {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}
.lower-price {
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 0;
}
.label {
  font-size: 12px;
  font-weight: 400;
  color: color.$gray90;
  line-height: 1;
}
.tilde {
  display: flex;
  flex-direction: column;
  width: 63px;
  padding: 0px 24px;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
  color: color.$gray90;
}
.upper-price {
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 0;
}
.price-pull-down {
  width: 120px;
  height: 46px;
  padding: 11.5px 16px;
  border-radius: 8px;
  border: 1px solid color.$gray20;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  outline: none;

  /* 下矢印を消す */
  appearance: none;
  select::-ms-expand {
    display: none;
  }
}
.icon {
  position: absolute;
  right: 6.5px;
  top: 10.5px;
  pointer-events: none;
}
.relative {
  position: relative;
}
