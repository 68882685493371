@use 'styles/color';

.container {
  display: flex;
  flex-direction: column;
  background-color: color.$white;
  padding: 6px 40px 0px 40px;
  border-radius: 8px 8px 0 0;
}

// 左右のpaddingの大きさを調整
$ps: 7, 16;
@each $p in $ps {
  .section-container-p#{$p} {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: #{$p}px 0px;
  }
}

.section-title {
  display: flex;
  width: 110px;
  font-size: 14px;
  line-height: 2;
  color: color.$gray50;
}

.option-labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 832px;
  padding: 0;
}

.extra-option {
  position: relative;

  .extra-option-bubble-box {
    position: absolute;
    top: -94px;
    width: 343px;

    .extra-option-bubble-card {
      display: flex;
      position: relative;
      padding: 16px;
      border-radius: 4px;
      background-color: color.$white;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25));

      &::after {
        content: '';
        position: absolute;
        top: 90px;
        left: 241px;
        width: 0px;
        height: 0px;
        margin: auto;
        border-style: solid;
        border-color: color.$white transparent transparent transparent;
        border-width: 8px 8px 0 8px;
      }

      .extra-option-bubble-text {
        height: fit-content;
        margin: auto;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: color.$black;
      }
    }
  }

  .extra-option-help-circle {
    margin: 12px 8px 8px -18px;
  }
}
