@use 'styles/color';

.header {
  background-color: color.$linen;
  box-shadow: 0px -1px 0px 0px #e0e0e0 inset;
  border-radius: 7px 7px 0 0;
  padding: 16px 40px;
  font-size: 14px;
  color: color.$gray50;
  font-weight: 700;
}
