@use 'styles/color';

.dropdown {
  position: relative;
  width: 100%;
  left: auto;
  z-index: 10;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: -24px;
  border: 1px solid color.$gray20;
  border-radius: 8px;
}
