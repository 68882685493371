.bold {
  font-weight: 700;
}

.full {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
  overflow: hidden;
  text-overflow: ellipsis;
}
