@use 'styles/color';

.search_form_outer {
  margin: 32px 0px 32px 0px;
  background-color: color.$white;
  border-radius: 40px;
  border: 1px solid color.$gray20;
  cursor: pointer;
}
.input_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search_button_outer {
  display: flex;
  background-color: color.$soft-orange;
  border-radius: 40px;
}
.search_button_inner {
  flex-direction: column;
  width: 180px;
  padding: 18px 50px;
  display: flex;
}
.button_label {
  text-align: center;
  color: color.$white;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
}
.button_number {
  text-align: center;
  color: color.$white;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
}

.detail-number {
  color: color.$soft-orange;
}
