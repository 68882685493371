@use 'styles/color';

.footer_outer {
  display: flex;
  border-radius: 0 0 8px 8px;
  background-color: color.$white;
  padding: 25px 40px;
}
.footer_inner {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.clear_button {
  font-size: 15px;
  color: color.$gray50;
  font-weight: 700;
}
.save_button {
  width: 80px;
  background-color: color.$soft-orange;
  padding: 13px 25px;
  border-radius: 8px;
  font-size: 15px;
  color: color.$white;
  font-weight: 700;
}
