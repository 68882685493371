.box {
  display: flex;
  width: 100%;
  padding: 0;
}

.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}

.align-stretch {
  align-items: stretch;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-start {
  align-items: start;
}
.align-normal {
  align-items: normal;
}

.wrap {
  flex-wrap: wrap;
}
.nowrap {
  flex-wrap: nowrap;
}

.space-between {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-normal {
  justify-content: normal;
}

.box-item {
  flex-shrink: 0;
  flex-grow: 0;
}
