@use 'styles/color';

.items-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
  cursor: pointer;
}
.item-cell-wrapper {
  flex-shrink: 0;
  flex-grow: 0;
}
