@use 'styles/color';

.image-container {
  width: 56px;
  height: 56px;
  background-color: color.$gray10;
  border: 3px solid color.$gray10;
  border-radius: 35px;

  &.selected {
    background-color: color.$linen;
    border: 3px solid color.$soft-orange;
  }
}

.item-cell {
  display: flex;
  flex-direction: column;
  width: 56px;
}
