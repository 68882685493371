.selected {
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #757575;
}

.selected::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #e6aa5f;
}

.unselected {
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #757575;
}
